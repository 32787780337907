import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout skipContainer>
    <div className="viewport bg-primary text-white d-flex justify-content-center align-items-center">
      <h1>#JEANGELIQUE</h1>
    </div>
  </Layout>
)

export default IndexPage
